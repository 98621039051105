<template>
  <a-modal :title="'项目清单'" :width="640" :visible="visible" @ok="ok"  @cancel="cancel" >
    <a-form :form="form" v-bind="{ labelCol:{xs:{span:24},sm:{span:7}},wrapperCol:{xs:{span:24 },sm:{span:13}}}">
			<a-form-item label="隐藏字段" style="display:none;">
			</a-form-item>
      <a-form-item label="项目名称">
        <a-input v-decorator="[ 'title', { rules: [{ required: true, message: '' }] }]" />
      </a-form-item>
			<a-form-item label="是否必选">
			  <a-switch  v-decorator="[ 'title', { rules: [{ required: true, message: '' }] }]"/>
			</a-form-item>
			<a-form-item label="是否可选">
			  <a-switch  v-decorator="[ 'title', { rules: [{ required: true, message: '' }] }]"/>
			</a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import pick from "lodash.pick"
import Img from "../../../../components/Img.vue"

// 表单字段
const fields = ["title", "sort"]

export default {
	components:{
		Img
	},
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
			item:[]
    };
  },
  created() {
    fields.forEach((v) => this.form.getFieldDecorator(v))
  },
  methods: {
    init(e) {
			let self = this
      self.form.setFieldsValue(pick(e, fields))
      self.visible = true;
			setTimeout(function(){
				self.$refs.img.init(e.img);
			},10)	
    },
    cancel() {
			this.form.resetFields();
      this.visible = false;
    },
    ok() {
      this.form.validateFields((errors, values) => {
        if (!errors){
					this.form.resetFields();
					this.$emit("ok", values)
				} 
      });
    },
		img_ok(e){
			this.form.setFieldsValue({img:e})
		}
  },
}
</script>

<style>
img{ max-width:300px; }
</style>
